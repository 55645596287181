import { IAction } from 'store/store';
import { ISMSServiceProvider } from '../data/sms-provider-types';

export const smsActionTypes = {
  SAGA: {
    ADD_SMS_SETTING: 'sms/saga/ADD_SMS_SETTING',
    PULL_SMS_SETTING: 'sms/saga/PULL_SMS_SETTING',
    UPDATE_SMS_SETTING: 'sms/saga/UPDATE_SMS_SETTING',
    UPDATE_PHASE: 'sms/saga/UPDATE_PHASE'
  },
  STORE: {
    ADD_SMS_SETTING: 'sms/store/ADD_SMS_SETTING',
    PULL_SMS_SETTING: 'sms/store/PULL_SMS_SETTING',
    UPDATE_SMS_SETTING: 'sms/store/UPDATE_SMS_SETTING',
    UPDATE_PHASE: 'sms/store/UPDATE_PHASE'
  }
};

export interface ISMSState {
  setting: ISMSServiceProvider;
  phase?: string;
  error?: string;
  nextToken: string;
}

export type TSmsStoreActions = ISMSState & {
  id: string;
  settingInfo: Partial<ISMSServiceProvider>;
};

export type TSmsActionType = IAction<Partial<TSmsStoreActions>>;
