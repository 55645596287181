import { API } from 'aws-amplify';
import { put } from 'redux-saga/effects';

import { updateConfig } from 'graphql/mutations';
import { mailActionTypes, TMailActionType } from '../../types';

export function* sagaUpdateMailSetting({ payload }: TMailActionType) {
  // Update phase
  yield put({
    type: mailActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'updating', error: null }
  });

  const { settingInfo } = payload;

  try {
    const { data } = yield API.graphql({
      query: updateConfig,
      variables: {
        input: {
          id: 'email_sending_config',
          config: JSON.stringify(settingInfo)
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    if (data) {
      // Update person
      yield put({
        type: mailActionTypes.STORE.ADD_EMAIL_SETTING,
        payload: { setting: data.updateConfig }
      });

      // Update phase
      yield put({
        type: mailActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: mailActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: mailActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
