import { IAction } from 'store/store';
import { IMail, IUserMail } from '../data/email-types';
import { IEmailServiceProvider } from '../data/provider-types';

export const mailActionTypes = {
  SAGA: {
    ADD_EMAIL_SETTING: 'email/saga/ADD_EMAIL_SETTING',
    PULL_EMAIL_SETTING: 'email/saga/PULL_EMAIL_SETTING',
    UPDATE_EMAIL_SETTING: 'email/saga/UPDATE_EMAIL_SETTING',
    SEND_MAIL: 'email/saga/SEND_MAIL',
    UPDATE_PHASE: 'email/saga/UPDATE_PHASE'
  },
  STORE: {
    ADD_EMAIL_SETTING: 'email/store/ADD_EMAIL_SETTING',
    PULL_EMAIL_SETTING: 'email/store/PULL_EMAIL_SETTING',
    UPDATE_EMAIL_SETTING: 'email/store/UPDATE_EMAIL_SETTING',
    UPDATE_PHASE: 'email/store/UPDATE_PHASE'
  }
};

export interface IMailState {
  email?: IMail;
  mails: IUserMail[];
  draft: IMail;
  setting: IEmailServiceProvider;
  isCompose?: boolean;
  isReply?: boolean;
  phase?: string;
  error?: string;
  nextToken: string;
}

export type TMailStoreActions = IMailState & {
  id: string;
  settingInfo: Partial<IEmailServiceProvider>;
};

export type TMailActionType = IAction<Partial<TMailStoreActions>>;
