import { takeLatest } from 'redux-saga/effects';

import { smsActionTypes } from '../types';
import { sagaAddSmsSetting } from './setting/sagaAddSmsSetting';
import { sagaPullSmsSetting } from './setting/sagaPullSmsSetting';
import { sagaUpdateSmsSetting } from './setting/sagaUpdateSmsSetting';

export function* sagas() {
  yield takeLatest(smsActionTypes.SAGA.ADD_SMS_SETTING, sagaAddSmsSetting);
  yield takeLatest(smsActionTypes.SAGA.PULL_SMS_SETTING, sagaPullSmsSetting);
  yield takeLatest(smsActionTypes.SAGA.UPDATE_SMS_SETTING, sagaUpdateSmsSetting);
}
