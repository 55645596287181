import { all, spawn } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { authSagas, authReducer } from '../pages/auth/services';
import { saga as configSagas, reducer as configReducer } from './config';
import { reducer as i18nReducer } from './i18n';
import { saga as searchSagas, reducer as searchReducer } from './search';
import { saga as staticSagas, reducer as staticReducer } from './static';
import { userSagas, userReducer } from 'pages/profile/services';

import { postsReducer, postsSagas } from 'pages/posts/services';
import { filemanagerReducer } from 'pages/filemanager/redux/reducers';
import { mailReducer, mailSagas } from 'pages/mail/_store';
import { organizationReducer, organizationSagas } from 'pages/organization/_store';
import { schoolReducer, schoolSagas } from 'pages/school/_store';
import { studentsReducer, studentsSagas } from 'pages/students/_store';
import { personsSagas, personsReducer } from 'pages/persons/services';
import { usersSagas, usersReducer } from 'pages/users/services';
import { emailReducer, emailSagas } from 'pages/bulk-email/services';
import { smsReducer, smsSagas } from 'pages/sms/services';
import { alumniMatchesReducer, alumniMatchesSagas } from 'pages/alumni-matches/services';

export const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  posts: postsReducer,
  filemanager: filemanagerReducer,
  i18n: i18nReducer,
  mails: mailReducer,
  organization: organizationReducer,
  school: schoolReducer,
  search: searchReducer,
  static: staticReducer,
  students: studentsReducer,
  user: userReducer,
  users: usersReducer,
  persons: personsReducer,
  bulkEmail: emailReducer,
  sms: smsReducer,
  alumniMatches: alumniMatchesReducer
});

export function* rootSaga() {
  const mainSagas = [
    authSagas(),
    configSagas(),
    searchSagas(),
    personsSagas(),
    postsSagas(),
    staticSagas(),
    userSagas(),
    usersSagas(),
    emailSagas(),
    smsSagas(),
    alumniMatchesSagas()
  ];
  const allSagas = mainSagas.concat(mailSagas, organizationSagas, schoolSagas, studentsSagas);

  yield all(
    allSagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield saga;
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
