import { takeLatest } from 'redux-saga/effects';

import { alumniMatchActionTypes } from '../types';
import { sagaPullAlumniMatchData } from './sagaPullMatchData';
import { sagaUpdateAlumniMatchData } from './sagaUpdataMatchData';

export function* sagas() {
  yield takeLatest(alumniMatchActionTypes.SAGA.PULL_MATCH_DATA, sagaPullAlumniMatchData);
  yield takeLatest(alumniMatchActionTypes.SAGA.UPDATE_MATCH_DATA, sagaUpdateAlumniMatchData);
}
