import React from 'react';
import loadable from '@loadable/component';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const ListUsers = loadable(() => import('./ui/user/ListUsers'));

const routes = {
  path: 'users',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <ListUsers />
    }
  ]
};

export default routes;
