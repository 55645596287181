import { takeLatest } from 'redux-saga/effects';

import { mailActionTypes } from '../types';
import { sagaAddMailSetting } from './setting/sagaAddMailSetting';
import { sagaPullMailSetting } from './setting/sagaPullMailSetting';
import { sagaUpdateMailSetting } from './setting/sagaUpdateMailSetting';
import { sagaSendMail } from './email/sagaSendMail';

export function* sagas() {
  yield takeLatest(mailActionTypes.SAGA.ADD_EMAIL_SETTING, sagaAddMailSetting);
  yield takeLatest(mailActionTypes.SAGA.PULL_EMAIL_SETTING, sagaPullMailSetting);
  yield takeLatest(mailActionTypes.SAGA.UPDATE_EMAIL_SETTING, sagaUpdateMailSetting);
  yield takeLatest(mailActionTypes.SAGA.SEND_MAIL, sagaSendMail);
}
