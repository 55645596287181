import { TLang } from 'utils/shared-types';

// Global Api Url
export const globalApiUrl = 'https://api.schoost.com';

// Global Api Urls
export const GLOBAL_CITIES_URL = globalApiUrl + '/cities';
export const GLOBAL_COUNTRIES_URL = globalApiUrl + '/countries';
export const GLOBAL_LOCALES_URL = globalApiUrl + '/locales';
export const GLOBAL_STATES_URL = globalApiUrl + '/states';

interface IUpdateUrlOptions {
  lang?: TLang;
  userId?: string;
  schoolId?: number;
  period?: string;
  context?: string;
}

export const updateApiUrl = (url: string, options: IUpdateUrlOptions): string => {
  Object.keys(options).forEach((key) => {
    if (typeof key !== 'undefined') {
      url = url.replace('{' + key + '}', options[key]);
    }
  });

  return url;
};

// Logos
export const schoostLogo = 'https://schst.in/schoost';
export const schoostFavicon = 'https://schst.in/icon';
