import React from 'react';
import loadable, { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const SmsServiceProvider = Loadable(lazy(() => import('./ui/SmsServiceProvider')));
const BulkSms = Loadable(lazy(() => import('./ui/BulkSms')));

const routes = {
  path: 'sms',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'bulk',
      element: <BulkSms />
    },
    {
      path: 'settings',
      element: <SmsServiceProvider />
    }
  ]
};

export default routes;
