import { IAction } from 'store/store';
import { IAlumniMatch } from '../data/alumni-match-types';

export const alumniMatchActionTypes = {
  SAGA: {
    PULL_MATCH_DATA: 'alumniMatch/saga/PULL_MATCH_DATA',
    UPDATE_MATCH_DATA: 'alumniMatch/saga/UPDATE_MATCH_DATA',
    UPDATE_PHASE: 'alumniMatch/saga/UPDATE_PHASE'
  },
  STORE: {
    SET_MATCH_DATA: 'alumniMatch/store/SET_MATCH_DATA',
    UPDATE_MATCH_DATA: 'alumniMatch/store/UPDATE_MATCH_DATA',
    DELETE_MATCH_DATA: 'alumniMatch/store/DELETE_MATCH_DATA',
    UPDATE_PHASE: 'alumniMatch/store/UPDATE_PHASE'
  }
};

export interface IAlumniMatchState {
  alumniMatches: IAlumniMatch[];
  phase: string;
  error?: string;
}
export type TAlumniMatchStoreActions = IAlumniMatchState & {
  id: number;
  matchDataInfo: IAlumniMatch;
  alumniMatch: IAlumniMatch;
};

export type TAlumniMatchActionType = IAction<Partial<TAlumniMatchStoreActions>>;
