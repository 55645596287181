import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { IMailState, mailActionTypes, TMailActionType } from '../types';

export const initialState: IMailState = {
  mails: [],
  draft: null,
  setting: null,
  isCompose: false,
  phase: null,
  error: null,
  nextToken: null
};

export const reducer = persistReducer(
  { storage, key: 'mails' },
  (state: IMailState = initialState, action: TMailActionType): IMailState => {
    switch (action.type) {
      // ADD Setting
      case mailActionTypes.STORE.ADD_EMAIL_SETTING: {
        const { setting } = action.payload;
        return { ...state, setting };
      }
      // UPDATE PHASE
      case mailActionTypes.STORE.UPDATE_PHASE: {
        const { phase, error } = action.payload;
        return { ...state, phase, error };
      }
      default:
        return state;
    }
  }
);
