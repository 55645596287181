import { API } from 'aws-amplify';
import { put } from 'redux-saga/effects';

import { createPerson } from 'graphql/mutations';
import { userActionTypes, TUserActionType } from '../../types';

export function* sagaAddPerson({ payload }: TUserActionType) {
  // Update phase
  yield put({
    type: userActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'adding', error: null }
  });

  const { userInfo } = payload;

  try {
    const { data } = yield API.graphql({
      query: createPerson,
      variables: {
        input: {
          name: userInfo.name,
          last_name: userInfo.last_name,
          email: userInfo.email
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    if (data) {
      // Update person
      yield put({
        type: userActionTypes.STORE.ADD_USER,
        payload: { person: data.createPerson }
      });

      // Update phase
      yield put({
        type: userActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: userActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: userActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
