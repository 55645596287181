import { API } from 'aws-amplify';
import { getConfig } from 'graphql/queries';
import { put } from 'redux-saga/effects';

import { mailActionTypes, TMailActionType } from '../../types';

export function* sagaPullMailSetting({ payload }: TMailActionType) {
  // Update phase
  yield put({
    type: mailActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'loading', error: null }
  });

  const { id } = payload;

  try {
    const { data } = yield API.graphql({
      query: getConfig,
      variables: {
        id: id
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    if (data) {
      // Update person
      yield put({
        type: mailActionTypes.STORE.ADD_EMAIL_SETTING,
        payload: { setting: data.getConfig }
      });

      // Update phase
      yield put({
        type: mailActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: mailActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: mailActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
