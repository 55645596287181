import axios from 'axios';
import { put } from 'redux-saga/effects';

import { REST_API_URL } from 'config';
import { TAlumniMatchActionType, alumniMatchActionTypes } from '../types';

export function* sagaPullAlumniMatchData({ payload }: TAlumniMatchActionType) {
  // Update phase
  yield put({
    type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'loading', error: null }
  });

  try {
    const response = yield axios.get(`${REST_API_URL}/alumni_matches.json?pagination=false`);

    if (response.status === 200) {
      const matchData = response.data || [];

      yield put({
        type: alumniMatchActionTypes.STORE.SET_MATCH_DATA,
        payload: { alumniMatches: matchData }
      });

      // Update phase
      yield put({
        type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
