import axios from 'axios';
import moment from 'moment';
import { put } from 'redux-saga/effects';

import { REST_API_URL } from 'config';
import { personActionTypes, TPersonActionType } from '../../types';

export function* sagaAddPersonBulkData({ payload }: TPersonActionType) {
  // Update phase
  yield put({
    type: personActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'adding', error: null }
  });

  const { personExcelData } = payload;

  try {
    for (let i = 0; i < personExcelData.length; i++) {
      const birthDate = personExcelData[i].birthDate
        ? moment(new Date(personExcelData[i].birthDate)).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');

      const response = yield axios.post(`${REST_API_URL}/alumnis`, {
        name: personExcelData[i].name,
        secondName: personExcelData[i].secondName,
        lastName: personExcelData[i].lastName,
        birthDate: birthDate,
        gender: personExcelData[i].gender ? personExcelData[i].gender : 'UNKNOWN',
        occupation: personExcelData[i].occupation,
        graduationPeriod: personExcelData[i].graduationPeriod,
        graduationStatus: personExcelData[i].graduationStatus,
        educationStatus: personExcelData[i].educationStatus,
        maritalStatus: personExcelData[i].maritalStatus
          ? personExcelData[i].maritalStatus
          : 'UNKNOWN',
        phoneNumber: personExcelData[i].phoneNumber,
        email: personExcelData[i].email,
        linkedinUrl: personExcelData[i].linkedinUrl,
        twitterUrl: personExcelData[i].twitterUrl,
        facebookUrl: personExcelData[i].facebookUrl
      });

      if (response.status === 201) {
        // Update person
        yield put({
          type: personActionTypes.STORE.ADD_PERSON,
          payload: { person: personExcelData[i] }
        });

        // Remove person from excel
        yield put({
          type: personActionTypes.STORE.REMOVE_PERSON_FROM_EXCEL,
          payload: { id: personExcelData[i].id }
        });
      }
    }

    // Update phase
    yield put({
      type: personActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'success', error: null }
    });
  } catch (error) {
    // Update phase
    yield put({
      type: personActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
