import { IAction } from 'store/store';

import { ICUser } from '../data/user-types';

export const userActionTypes = {
  SAGA: {
    ADD_USER: 'persons/saga/ADD_USER',
    DELETE_USER: 'persons/saga/DELETE_USER',
    PULL_USERS: 'persons/saga/PULL_USERS',
    UPDATE_USER: 'persons/saga/UPDATE_USER',
    UPDATE_PHASE: 'persons/saga/UPDATE_PHASE'
  },
  STORE: {
    ADD_USER: 'persons/store/ADD_USER',
    DELETE_USER: 'persons/store/DELETE_USER',
    UPDATE_USERS: 'persons/store/UPDATE_USERS',
    UPDATE_PHASE: 'persons/store/UPDATE_PHASE'
  }
};

export interface ICUserState {
  users: ICUser[];
  phase: string;
  error?: string;
  nextToken: string;
}
export type TUserStoreActions = ICUserState & {
  id: string;
  user: ICUser;
  reset: boolean;
  userInfo: Partial<ICUser>;
};

export type TUserActionType = IAction<Partial<TUserStoreActions>>;
