import { call, put } from 'redux-saga/effects';
import { getS3File } from 'utils/amplifyUtils';

import { postActionTypes, TPostActionType } from '../../types';

export function* sagaUpsertDraft({ payload }: TPostActionType) {
  const { post } = payload;

  if (post.coverKey !== '' && post.coverUrl === '') {
    console.log('get url');
    const coverUrl = yield call(getS3File, post.coverKey, 'public');
    if (coverUrl) {
      post.coverUrl = coverUrl;
    }
  }

  // Dispatch to the store
  yield put({
    type: postActionTypes.STORE.UPSERT_DRAFT,
    payload: payload
  });
}
