import { API } from 'aws-amplify';
import { put } from 'redux-saga/effects';

import { createConfig } from 'graphql/mutations';
import { smsActionTypes, TSmsActionType } from '../../types';

export function* sagaAddSmsSetting({ payload }: TSmsActionType) {
  // Update phase
  yield put({
    type: smsActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'adding', error: null }
  });

  const { settingInfo } = payload;

  try {
    const { data } = yield API.graphql({
      query: createConfig,
      variables: {
        input: {
          id: 'sms_sending_config',
          config: JSON.stringify(settingInfo)
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    if (data) {
      // Update person
      yield put({
        type: smsActionTypes.STORE.ADD_SMS_SETTING,
        payload: { setting: data.createConfig }
      });

      // Update phase
      yield put({
        type: smsActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: smsActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: smsActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
