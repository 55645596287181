import { put, takeLatest } from '@redux-saga/core/effects';
import objectPath from 'object-path';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSelector } from 'reselect';

import { IAuthUser } from 'pages/auth/data/account-types';
import {
  IGradingTerm,
  ISchool,
  ISeason,
  TConfiguration
} from 'pages/organization/organization-types';
import { TLang } from 'utils/shared-types';

import { IAction, TPhase } from './store';

interface IConfigState {
  currency: string;
  system: string[];
  phase: TPhase;
}
interface IParentCongifState {
  config: IConfigState;
}
type TActionAllState = IConfigState & {
  lang?: TLang;
  user?: IAuthUser;
  id?: number;
  active?: boolean;
  season?: ISeason;
  activeSchool?: ISchool;
  seasonInfo?: Partial<ISeason>;
  applyChildren?: boolean;
  gradingTermInfo?: Partial<IGradingTerm>;
  configInfo?: TConfiguration;
  sourceDb?: string;
  destinationDb?: string;
};
type TActionType = IAction<Partial<TActionAllState>>;

export const actionTypes = {
  PULL_SYSTEM_CONFIGURATION: 'config/PULL_SYSTEM_CONFIGURATION',
  SET_SYSTEM_CONFIGURATION: 'config/SET_SYSTEM_CONFIGURATION',
  UPDATE_SYSTEM_CONFIG: 'config/UPDATE_SYSTEM_CONFIG',
  SET_SYSTEM_CONFIG: 'config/SET_SYSTEM_CONFIG',
  CONFIG_SET_CURRENCY: 'config/SET_CURRENCY',
  PULL_GRADE_LEVELS: 'config/PULL_GRADE_LEVELS',
  SET_GRADE_LEVELS: 'config/SET_GRADE_LEVELS',
  ADD_GRADE_LEVEL: 'config/ADD_GRADE_LEVEL',
  UPDATE_GRADE_LEVEL: 'config/UPDATE_GRADE_LEVEL',
  SET_GRADE_LEVEL: 'config/SET_GRADE_LEVEL',
  DELETE_GRADE_LEVEL: 'config/DELETE_GRADE_LEVEL',
  REMOVE_GRADE_LEVEL: 'config/REMOVE_GRADE_LEVEL',
  PULL_DATABASES: 'config/PULL_DATABASES',
  SET_DATABASES: 'config/SET_DATABASES',
  ADD_DATABASE: 'config/ADD_DATABASE',
  ADD_SEASON: 'config/ADD_SEASON',
  UPDATE_SEASON: 'config/UPDATE_SEASON',
  DELETE_SEASON: 'config/DELETE_SEASON',
  SET_DEFAULT_SEASON: 'config/SET_DEFAULT_SEASON',
  SET_SEASON: 'config/SET_SEASON',
  COPY_SEASON_DATA: 'config/COPY_SEASON_DATA',
  ADD_GRADING_TERM: 'config/ADD_GRADING_TERM',
  UPDATE_GRADING_TERM: 'config/UPDATE_GRADING_TERM',
  DELETE_GRADING_TERM: 'config/DELETE_GRADING_TERM',
  SET_PHASE: 'config/SET_PHASE'
};

export const initialState = {
  currency: 'USD',
  gradeLevels: [],
  seasons: [],
  system: [],
  phase: null
};

export const configCurrencySelector = createSelector(
  (state: IParentCongifState) => objectPath.get(state, ['config', 'currency']),
  (currency: string) => currency
);
export const systemConfigSelector = createSelector(
  (state: IParentCongifState) => objectPath.get(state, ['config', 'system']),
  (system: Record<string, string>) => system
);
export const configPhaseSelector = createSelector(
  (state: IParentCongifState) => objectPath.get(state, ['config', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  { storage, key: 'config' },
  (state: IConfigState = initialState, action: TActionType): IConfigState => {
    switch (action.type) {
      case actionTypes.SET_SYSTEM_CONFIGURATION: {
        const { system } = action.payload;
        return { ...state, system };
      }
      case actionTypes.CONFIG_SET_CURRENCY: {
        const { currency } = action.payload;
        return { ...state, currency };
      }
      case actionTypes.SET_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const configActions = {
  pullConfiguration: (): TActionType => ({
    type: actionTypes.PULL_SYSTEM_CONFIGURATION
  }),
  updateConfig: (configInfo: TConfiguration, id?: number): TActionType => ({
    type: actionTypes.UPDATE_SYSTEM_CONFIG,
    payload: { configInfo, id }
  }),
  setPhase: (phase: TPhase): TActionType => ({
    type: actionTypes.SET_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  // Pull system configuration
  yield takeLatest(actionTypes.PULL_SYSTEM_CONFIGURATION, function* pullConfigurationSaga() {
    yield put(configActions.setPhase('loading'));

    yield put(configActions.setPhase('success'));
  });

  // Update system configuration
  yield takeLatest(
    actionTypes.UPDATE_SYSTEM_CONFIG,
    function* setSystemConfigSaga({ payload }: TActionType) {
      yield put(configActions.setPhase('updating'));

      // Update the store
      yield put(configActions.pullConfiguration());
    }
  );
}
