import { Store } from 'redux';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export default function setupAxios(axios: AxiosInstance, store: Store) {
  axios.interceptors.request.use(
    (config) => {
      // Note: We are not using user based auth token here. This code is just for further use if needed
      const {
        auth: { accessToken }
      } = store.getState();

      if (accessToken && !config.headers.Authorization) {
        config.headers['Authorization'] = `Bearer ${process.env.REACT_APP_API_ACCESS_TOKEN}`;
      }
      if (config.method === 'patch') {
        config.headers['Content-Type'] = 'application/merge-patch+json';
      } else {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
      }

      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      return error.response;
    }
  );
}
