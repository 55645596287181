import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { ISMSState, smsActionTypes, TSmsActionType } from '../types';

export const initialState: ISMSState = {
  setting: null,
  phase: null,
  error: null,
  nextToken: null
};

export const reducer = persistReducer(
  { storage, key: 'mails' },
  (state: ISMSState = initialState, action: TSmsActionType): ISMSState => {
    switch (action.type) {
      // ADD Setting
      case smsActionTypes.STORE.ADD_SMS_SETTING: {
        const { setting } = action.payload;
        return { ...state, setting };
      }
      // UPDATE PHASE
      case smsActionTypes.STORE.UPDATE_PHASE: {
        const { phase, error } = action.payload;
        return { ...state, phase, error };
      }
      default:
        return state;
    }
  }
);
