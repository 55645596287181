import React from 'react';
import loadable, { lazy } from '@loadable/component';

import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const ListAlumniMatches = Loadable(lazy(() => import('./ui/ListAlumniMatches')));

const routes = {
  path: 'alumni-match',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <ListAlumniMatches />
    },
    {
      path: ':id/:action',
      element: <ListAlumniMatches />
    }
  ]
};

export default routes;
