import { IAction } from 'store/store';

import { IPerson } from '../data/person-types';

export const personActionTypes = {
  SAGA: {
    ADD_PERSON: 'persons/saga/ADD_PERSON',
    ADD_PERSON_BULK_DATA: 'persons/saga/ADD_PERSON_BULK_DATA',
    ADD_PERSON_EXCEL_DATA: 'persons/saga/ADD_PERSON_EXCEL_DATA',
    DELETE_PERSON: 'persons/saga/DELETE_PERSON',
    PULL_PEOPLE: 'persons/saga/PULL_PEOPLE',
    UPDATE_PERSON: 'persons/saga/UPDATE_PERSON',
    UPDATE_PHASE: 'persons/saga/UPDATE_PHASE'
  },
  STORE: {
    ADD_PERSON: 'persons/store/ADD_PERSON',
    ADD_PERSON_EXCEL_DATA: 'persons/store/ADD_PERSON_EXCEL_DATA',
    DELETE_PERSON: 'persons/store/DELETE_PERSON',
    REMOVE_PERSON_FROM_EXCEL: 'persons/store/REMOVE_PERSON_FROM_EXCEL',
    UPDATE_PEOPLE: 'persons/store/UPDATE_PEOPLE',
    UPDATE_PHASE: 'persons/store/UPDATE_PHASE'
  }
};

export interface IPersonState {
  persons: IPerson[];
  personExcelData: IPerson[];
  phase: string;
  error?: string;
}
export type TPersonsStoreActions = IPersonState & {
  id: number;
  person: IPerson;
  personInfo: Partial<IPerson>;
};

export type TPersonActionType = IAction<Partial<TPersonsStoreActions>>;
