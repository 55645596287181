import axios from 'axios';
import { put } from 'redux-saga/effects';

import { REST_API_URL } from 'config';
import { TAlumniMatchActionType, alumniMatchActionTypes } from '../types';

export function* sagaUpdateAlumniMatchData({ payload }: TAlumniMatchActionType) {
  // Update phase
  yield put({
    type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'updating', error: null }
  });

  try {
    const { matchDataInfo } = payload;

    const response = yield axios.patch(`${REST_API_URL}/alumni_matches/${matchDataInfo.id}`, {
      userId: matchDataInfo.userId,
      alumniId: matchDataInfo.alumniId,
      pairDate: matchDataInfo.pairDate,
      approved: matchDataInfo.approved,
      approvalDate: matchDataInfo.approvalDate,
      approver: matchDataInfo.approver
    });

    if (response.status === 200) {
      yield put({
        type: alumniMatchActionTypes.STORE.UPDATE_MATCH_DATA,
        payload: { alumniMatch: matchDataInfo }
      });

      // Update phase
      yield put({
        type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'updating-success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'updating-error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: alumniMatchActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'updating-error', error: error }
    });
  }
}
