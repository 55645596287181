import React from 'react';
import loadable, { lazy } from '@loadable/component';
import { Loadable } from 'layout';

const AuthGuard = loadable(() => import('components/guard/AuthGuard'));
const MainLayout = loadable(() => import('layout/MainLayout'));

const BulkEmail = Loadable(lazy(() => import('./ui/BulkEmail')));
const EmailServiceProvider = Loadable(lazy(() => import('./ui/EmailServiceProvider')));

const routes = {
  path: 'email',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'bulk',
      element: <BulkEmail />
    },
    {
      path: 'settings',
      element: <EmailServiceProvider />
    }
  ]
};

export default routes;
