import produce from 'immer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { IPersonState, personActionTypes, TPersonActionType } from '../types';

export const initialState: IPersonState = {
  persons: [],
  personExcelData: [],
  phase: null,
  error: null
};

export const reducer = persistReducer(
  { storage, key: 'persons' },
  (state: IPersonState = initialState, action: TPersonActionType): IPersonState => {
    switch (action.type) {
      // ADD PERSON EXCEL DATA
      case personActionTypes.STORE.ADD_PERSON_EXCEL_DATA: {
        const { personExcelData } = action.payload;
        return { ...state, personExcelData };
      }
      // ADD PERSON
      case personActionTypes.STORE.ADD_PERSON: {
        const { person } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.persons.findIndex((d) => d.id === person.id);
          if (index > -1) {
            draftState.persons[index] = person;
          } else {
            draftState.persons.unshift(person);
          }
        });
      }
      // DELETE PERSON
      case personActionTypes.STORE.DELETE_PERSON: {
        const { id } = action.payload;
        const persons = { ...state }.persons.filter((d) => d.id !== id);
        return { ...state, persons };
      }
      // REMOVE PERSON FROM EXCEL
      case personActionTypes.STORE.REMOVE_PERSON_FROM_EXCEL: {
        const { id } = action.payload;
        const personExcelData = { ...state }.personExcelData.filter((d) => d.id !== id);
        return { ...state, personExcelData };
      }
      // UPDATE PHASE
      case personActionTypes.STORE.UPDATE_PHASE: {
        const { phase, error } = action.payload;
        return { ...state, phase, error };
      }
      // UPDATE PEOPLE
      case personActionTypes.STORE.UPDATE_PEOPLE: {
        const { persons } = action.payload;

        return { ...state, persons };
      }
      default:
        return state;
    }
  }
);
