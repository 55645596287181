import { ICUser } from './user-types';

export const convertCognitoUsers = (users: any) => {
  const returnUsers: ICUser[] = [];

  users.forEach((user: any) => {
    const attributes = user.Attributes;

    returnUsers.push({
      id: user.Username,
      name: attributes.find((a: any) => a.Name === 'name').Value,
      last_name: attributes.find((a: any) => a.Name === 'family_name').Value,
      email: attributes.find((a: any) => a.Name === 'email').Value,
      email_verified: attributes.find((a: any) => a.Name === 'email_verified').Value,
      enabled: user.Enabled,
      status: user.UserStatus,
      created_at: user.UserCreateDate
    });
  });

  return returnUsers;
};
