import produce from 'immer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IAlumniMatchState, TAlumniMatchActionType, alumniMatchActionTypes } from '../types';

export const initialState: IAlumniMatchState = {
  alumniMatches: [],
  phase: null,
  error: null
};

export const reducer = persistReducer(
  { storage, key: 'persons' },
  (state: IAlumniMatchState = initialState, action: TAlumniMatchActionType): IAlumniMatchState => {
    switch (action.type) {
      case alumniMatchActionTypes.STORE.SET_MATCH_DATA: {
        const { alumniMatches } = action.payload;

        return { ...state, alumniMatches };
      }
      case alumniMatchActionTypes.STORE.UPDATE_MATCH_DATA: {
        const { alumniMatch } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.alumniMatches.findIndex((d) => d.id === alumniMatch.id);
          if (index > -1) {
            draftState.alumniMatches[index] = alumniMatch;
          } else {
            draftState.alumniMatches.unshift(alumniMatch);
          }
        });
      }
      case alumniMatchActionTypes.STORE.DELETE_MATCH_DATA: {
        const { id } = action.payload;
        const alumniMatches = { ...state }.alumniMatches.filter((d) => d.id !== id);
        return { ...state, alumniMatches };
      }
      case alumniMatchActionTypes.STORE.UPDATE_PHASE: {
        const { phase, error } = action.payload;
        return { ...state, phase, error };
      }
      default:
        return state;
    }
  }
);
