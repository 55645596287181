import { API, Auth } from 'aws-amplify';
import { put } from 'redux-saga/effects';

import { convertCognitoUsers } from '../../../data/convertUsers';
import { TUserActionType, userActionTypes } from '../../types';

export function* sagaPullUsers({ payload }: TUserActionType) {
  // Update phase
  yield put({
    type: userActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'loading', error: null }
  });

  const { nextToken } = payload;
  const reset = nextToken ? false : true;

  try {
    const currentUser = yield Auth.currentSession();
    const userToken = currentUser.getAccessToken().getJwtToken();

    let apiName = 'AdminQueries';
    let path = '/listUsers';
    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Credentials': true,
        Authorization: `Bearer ${userToken}`
      }
    };
    const { NextToken, ...rest } = yield API.get(apiName, path, myInit);
    console.log(rest);

    if (rest) {
      const users = convertCognitoUsers(rest.Users);
      const nextToken = NextToken;

      // Update Users
      yield put({
        type: userActionTypes.STORE.UPDATE_USERS,
        payload: { users: users, nextToken: nextToken, reset: reset }
      });

      // Update phase
      yield put({
        type: userActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: userActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: userActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
