import axios from 'axios';
import { put } from 'redux-saga/effects';

import { REST_API_URL } from 'config';
import { TUserActionType, userActionTypes } from '../../types';

export function* sagaSendPersonMatchData({ payload }: TUserActionType) {
  // Update phase
  yield put({
    type: userActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'loading-match-data', error: null }
  });

  try {
    const { personMatchData } = payload;

    const response = yield axios.post(`${REST_API_URL}/alumni_matches`, {
      userId: personMatchData.userId,
      alumniId: personMatchData.alumniId,
      pairDate: personMatchData.pairDate,
      approved: personMatchData.approved
    });

    if (response.status === 201) {
      const matchData = response.data || [];

      // Update people
      yield put({
        type: userActionTypes.STORE.UPDATE_MATCH_DATA,
        payload: { matchData: matchData }
      });

      // Update phase
      yield put({
        type: userActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success-match-data', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: userActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error-match-data', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: userActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error-match-data', error: error }
    });
  }
}
