import { IPost } from 'pages/posts/data/post-types';
import { all, call } from 'redux-saga/effects';

import { getS3File } from 'utils/amplifyUtils';

export function* getPostImages(posts: IPost[], imageKey: string) {
  try {
    const coverCalls = [];

    posts.forEach((post: IPost) => {
      coverCalls.push(call(getS3File, post[imageKey + 'Key']));
    });
    const coverUrls = yield all(coverCalls);

    posts.forEach((post: IPost, index: number) => {
      post[imageKey + 'Url'] = coverUrls[index];
    });

    return yield posts;
  } catch (error) {
    console.log(error);
    return error;
  }
}
