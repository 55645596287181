import produce from 'immer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { ICUserState, userActionTypes, TUserActionType } from '../types';

export const initialState: ICUserState = {
  users: [],
  phase: null,
  error: null,
  nextToken: null
};

export const reducer = persistReducer(
  { storage, key: 'users' },
  (state: ICUserState = initialState, action: TUserActionType): ICUserState => {
    switch (action.type) {
      // ADD USER
      case userActionTypes.STORE.ADD_USER: {
        const { user } = action.payload;
        return produce(state, (draftState) => {
          const index = draftState.users.findIndex((d) => d.id === user.id);
          if (index > -1) {
            draftState.users[index] = user;
          } else {
            draftState.users.unshift(user);
          }
        });
      }
      // DELETE USER
      case userActionTypes.STORE.DELETE_USER: {
        const { id } = action.payload;
        const users = { ...state }.users.filter((d) => d.id !== id);
        return { ...state, users };
      }
      // UPDATE PHASE
      case userActionTypes.STORE.UPDATE_PHASE: {
        const { phase, error } = action.payload;
        return { ...state, phase, error };
      }
      // UPDATE PEOPLE
      case userActionTypes.STORE.UPDATE_USERS: {
        const { users, nextToken, reset } = action.payload;

        if (reset) {
          return { ...state, users, nextToken };
        }

        return produce(state, (draftState) => {
          draftState.users = [...draftState.users, ...users];
          draftState.nextToken = nextToken;
        });
      }
      default:
        return state;
    }
  }
);
