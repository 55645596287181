import { takeLatest } from 'redux-saga/effects';

import { userActionTypes } from '../types';

import { sagaAddPerson } from './user/sagaAddUser';
import { sagaDeletePerson } from './user/sagaDeleteUser';
import { sagaPullUsers } from './user/sagaPullUsers';
import { sagaUpdatePerson } from './user/sagaUpdateUser';
import { sagaUpdatePhase } from './sagaUpdatePhase';

export function* sagas() {
  yield takeLatest(userActionTypes.SAGA.ADD_USER, sagaAddPerson);
  yield takeLatest(userActionTypes.SAGA.DELETE_USER, sagaDeletePerson);
  yield takeLatest(userActionTypes.SAGA.PULL_USERS, sagaPullUsers);
  yield takeLatest(userActionTypes.SAGA.UPDATE_USER, sagaUpdatePerson);
  yield takeLatest(userActionTypes.SAGA.UPDATE_PHASE, sagaUpdatePhase);
}
