import { takeLatest } from 'redux-saga/effects';

import { personActionTypes } from '../types';

import { sagaAddPerson } from './person/sagaAddPerson';
import { sagaDeletePerson } from './person/sagaDeletePerson';
import { sagaPullPeople } from './person/sagaPullPeople';
import { sagaUpdatePerson } from './person/sagaUpdatePerson';
import { sagaUpdatePhase } from './sagaUpdatePhase';

import { sagaAddPersonBulkData } from './import/sagaAddPersonBulkData';
import { sagaAddPersonExcelData } from './import/sagaAddPersonExcelData';

export function* sagas() {
  yield takeLatest(personActionTypes.SAGA.ADD_PERSON, sagaAddPerson);
  yield takeLatest(personActionTypes.SAGA.ADD_PERSON_BULK_DATA, sagaAddPersonBulkData);
  yield takeLatest(personActionTypes.SAGA.ADD_PERSON_EXCEL_DATA, sagaAddPersonExcelData);
  yield takeLatest(personActionTypes.SAGA.DELETE_PERSON, sagaDeletePerson);
  yield takeLatest(personActionTypes.SAGA.PULL_PEOPLE, sagaPullPeople);
  yield takeLatest(personActionTypes.SAGA.UPDATE_PERSON, sagaUpdatePerson);
  yield takeLatest(personActionTypes.SAGA.UPDATE_PHASE, sagaUpdatePhase);
}
