import axios from 'axios';
import { put } from 'redux-saga/effects';
import moment from 'moment';

import { personActionTypes, TPersonActionType } from '../../types';
import { REST_API_URL } from 'config';

export function* sagaUpdatePerson({ payload }: TPersonActionType) {
  // Update phase
  yield put({
    type: personActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'updating', error: null }
  });

  const { personInfo } = payload;
  const birthDate = personInfo.birthDate
    ? moment(new Date(personInfo.birthDate)).format('YYYY-MM-DD')
    : null;

  try {
    const response = yield axios.patch(`${REST_API_URL}/alumnis/${personInfo.id}`, {
      ssnNumber: personInfo.ssnNumber,
      schoolNumber: personInfo.schoolNumber,
      name: personInfo.name,
      secondName: personInfo.secondName,
      lastName: personInfo.lastName,
      birthDate: birthDate,
      gender: personInfo?.gender || 'UNKNOWN',
      studentPicture: personInfo.studentPicture,
      occupation: personInfo.occupation,
      graduationPeriod: personInfo.graduationPeriod,
      graduationStatus: personInfo.graduationStatus,
      educationStatus: personInfo.educationStatus.toString(),
      maritalStatus: personInfo?.maritalStatus || 'UNKNOWN',
      phoneNumber: personInfo.phoneNumber,
      email: personInfo.email,
      linkedinUrl: personInfo.linkedinUrl,
      twitterUrl: personInfo.twitterUrl,
      facebookUrl: personInfo.facebookUrl
    });

    if (response.status === 200) {
      // Update person
      yield put({
        type: personActionTypes.STORE.ADD_PERSON,
        payload: { person: personInfo }
      });

      // Update phase
      yield put({
        type: personActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: personActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: personActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
