import objectPath from 'object-path';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { IFilter } from 'components/filter/Filter';
import { IAuthUser } from 'pages/auth/data/account-types';
import { IAction } from 'store/store';
import { TLang } from 'utils/shared-types';

import { TSchoolFormValues } from '../add/AddSchool';
import { ISchool } from '../organization-types';

interface ISchoolState {
  school?: ISchool;
  filters?: IFilter[];
  phase?: string;
}
interface IOrganizationState {
  organization: ISchoolState;
}
type IActionSchoolState = ISchoolState & {
  user?: IAuthUser;
  lang?: TLang;
  schoolId?: number;
  activeSchoolId?: number;
  schoolInfo?: Partial<ISchool>;
  values?: TSchoolFormValues;
};

export const schoolActionTypes = {
  SCHOOL_PULL: 'school/SCHOOL_PULL',
  SCHOOL_SET: 'school/SCHOOL_SET',
  SCHOOL_ADD: 'school/SCHOOL_ADD',
  SCHOOL_UPDATE: 'school/SCHOOL_UPDATE',
  SCHOOL_DELETE: 'school/SCHOOL_DELETE',
  SCHOOL_RESET: 'school/SCHOOL_RESET',
  SCHOOL_PHASE: 'school/SCHOOL_PHASE',
  SCHOOL_FILTERS: 'school/SCHOOL_FILTERS',
  SCHOOL_UPDATE_SIDEBAR: 'SCHOOL_UPDATE_SIDEBAR'
};

const initialState: ISchoolState = {
  school: null,
  filters: [],
  phase: null
};

export const schoolSelector = createSelector(
  (state: IOrganizationState) => objectPath.get(state, ['organization', 'school']),
  (school: ISchool) => school
);
export const schoolFiltersSelector = createSelector(
  (state: IOrganizationState) => objectPath.get(state, ['organization', 'filters']),
  (filters: IFilter[]) => filters
);
export const schoolPhaseSelector = createSelector(
  (state: IOrganizationState) => objectPath.get(state, ['organization', 'phase']),
  (phase: string) => phase
);

export const reducer = persistReducer(
  {
    storage,
    key: 'school'
  },
  (state: ISchoolState = initialState, action: IAction<ISchoolState>): ISchoolState => {
    switch (action.type) {
      case schoolActionTypes.SCHOOL_PULL: {
        return { ...state, phase: null };
      }
      case schoolActionTypes.SCHOOL_SET: {
        const { school } = action.payload;
        return { ...state, school };
      }
      case schoolActionTypes.SCHOOL_RESET: {
        return { ...state, school: null };
      }
      case schoolActionTypes.SCHOOL_FILTERS: {
        const { filters } = action.payload;
        return { ...state, filters };
      }
      case schoolActionTypes.SCHOOL_PHASE: {
        const { phase } = action.payload;
        return { ...state, phase };
      }
      default:
        return state;
    }
  }
);

export const schoolActions = {
  pullSchoolInfo: (schoolId: number): IAction<{ schoolId: number }> => ({
    type: schoolActionTypes.SCHOOL_PULL,
    payload: { schoolId }
  }),
  addSchool: (
    user: IAuthUser,
    lang: TLang,
    values: TSchoolFormValues
  ): IAction<IActionSchoolState> => ({
    type: schoolActionTypes.SCHOOL_ADD,
    payload: { user, lang, values }
  }),
  updateSchoolInfo: (school: ISchool): IAction<ISchoolState> => ({
    type: schoolActionTypes.SCHOOL_SET,
    payload: { school }
  }),
  saveSchoolInfo: (
    user: IAuthUser,
    lang: TLang,
    schoolId: number,
    schoolInfo: Partial<ISchool>
  ): IAction<IActionSchoolState> => ({
    type: schoolActionTypes.SCHOOL_UPDATE,
    payload: { user, lang, schoolId, schoolInfo }
  }),
  deleteSchool: (
    user: IAuthUser,
    lang: TLang,
    activeSchoolId: number,
    schoolId: number
  ): IAction<IActionSchoolState> => ({
    type: schoolActionTypes.SCHOOL_DELETE,
    payload: { lang, user, activeSchoolId, schoolId }
  }),
  setSchoolInfo: (school: ISchool): IAction<ISchoolState> => ({
    type: schoolActionTypes.SCHOOL_SET,
    payload: { school }
  }),
  setFilters: (filters: IFilter[]): IAction<ISchoolState> => ({
    type: schoolActionTypes.SCHOOL_FILTERS,
    payload: { filters }
  }),
  setPhase: (phase: string): IAction<ISchoolState> => ({
    type: schoolActionTypes.SCHOOL_PHASE,
    payload: { phase }
  })
};

export function* saga() {
  yield takeLatest(
    schoolActionTypes.SCHOOL_PULL,
    function* schoolPullSaga({ payload }: IAction<{ schoolId: number }>) {
      // yield put(schoolActions.setPhase('loading'));
      // const { schoolId } = payload;
      // const response = yield axios.get(`${SCHOOLS_API_URL}/${schoolId}`);
      // if (response.status !== 200) {
      //   yield put(schoolActions.setPhase('error'));
      //   return;
      // }
      // yield put({
      //   type: schoolActionTypes.SCHOOL_SET,
      //   payload: { school: response.data }
      // });
      // yield put(schoolActions.setPhase('success'));
    }
  );

  yield takeLatest(
    schoolActionTypes.SCHOOL_ADD,
    function* schoolAddSaga({ payload }: IAction<IActionSchoolState>) {
      // yield put(schoolActions.setPhase('school-adding'));
    }
  );

  yield takeLatest(
    schoolActionTypes.SCHOOL_UPDATE,
    function* schoolUpdateSaga({ payload }: IAction<IActionSchoolState>) {
      // yield put(schoolActions.setPhase('school-updating'));
    }
  );
}
