import axios from 'axios';
import { put } from 'redux-saga/effects';

import { REST_API_URL } from 'config';
import { personActionTypes, TPersonActionType } from '../../types';

export function* sagaPullPeople({ payload }: TPersonActionType) {
  // Update phase
  yield put({
    type: personActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'loading', error: null }
  });

  try {
    const response = yield axios.get(`${REST_API_URL}/alumnis.json?pagination=false`);

    if (response.status === 200) {
      const persons = response.data || [];

      // Update people
      yield put({
        type: personActionTypes.STORE.UPDATE_PEOPLE,
        payload: { persons: persons }
      });

      // Update phase
      yield put({
        type: personActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'success', error: null }
      });
    } else {
      // Update phase
      yield put({
        type: personActionTypes.STORE.UPDATE_PHASE,
        payload: { phase: 'error', error: 'Error occurred!' }
      });
    }
  } catch (error) {
    // Update phase
    yield put({
      type: personActionTypes.STORE.UPDATE_PHASE,
      payload: { phase: 'error', error: error }
    });
  }
}
