import { put } from 'redux-saga/effects';
import { mailActionTypes, TMailActionType } from '../../types';

export function* sagaSendMail({ payload }: TMailActionType) {
  // Update phase
  yield put({
    type: mailActionTypes.STORE.UPDATE_PHASE,
    payload: { phase: 'adding', error: null }
  });

  const { email, isReply } = payload;
  console.log('Send mail saga new');
  console.log(email, isReply);
}
