import objectPath from 'object-path';
import { createSelector } from 'reselect';
import { IUser } from 'pages/profile/data/user-types';
import { IUserStoreState } from '../types';
import { IPersonMatch } from 'pages/profile/data/person-match-types';

export const userProfileSelector = createSelector(
  (state: IUserStoreState) => objectPath.get(state, ['user', 'profile']),
  (profile: IUser) => profile
);
export const userMatchDataSelector = createSelector(
  (state: IUserStoreState) => objectPath.get(state, ['user', 'matchData']),
  (matchData: IPersonMatch) => matchData
);
export const userPhaseSelector = createSelector(
  (state: IUserStoreState) => objectPath.get(state, ['user', 'phase']),
  (userPhase: string) => userPhase
);
export const userErrorSelector = createSelector(
  (state: IUserStoreState) => objectPath.get(state, ['user', 'error']),
  (userError: string) => userError
);
