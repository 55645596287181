import { takeLatest } from 'redux-saga/effects';

import { userActionTypes } from 'pages/profile/services/types';

import { sagaUpdatePhase } from './user/sagaUpdatePhase';
import { sagaGetUserProfile } from './user/sagaGetUserProfile';
import { sagaUpdateUserProfile } from './user/sagaUpdateUserProfile';
import { sagaUpdateUserProfileImages } from './user/sagaUpdateUserProfileImages';
import { sagaPullPersonMatchData } from './match/sagaGetMatchData';
import { sagaSendPersonMatchData } from './match/sagaSendMatchData';
import { sagaDeletePersonMatchData } from './match/sagaDeleteMatchData';

export function* sagas() {
  yield takeLatest(userActionTypes.SAGA.GET_PROFILE, sagaGetUserProfile);
  yield takeLatest(userActionTypes.SAGA.UPDATE_PROFILE, sagaUpdateUserProfile);
  yield takeLatest(userActionTypes.SAGA.UPDATE_IMAGES, sagaUpdateUserProfileImages);
  yield takeLatest(userActionTypes.SAGA.GET_MATCH_DATA, sagaPullPersonMatchData);
  yield takeLatest(userActionTypes.SAGA.SEND_MATCH_DATA, sagaSendPersonMatchData);
  yield takeLatest(userActionTypes.SAGA.DELETE_MATCH_DATA, sagaDeletePersonMatchData);
  yield takeLatest(userActionTypes.SAGA.UPDATE_PHASE, sagaUpdatePhase);
}
